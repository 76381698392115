/** @jsx jsx */
import { jsx } from '@emotion/react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, FreeMode, Navigation } from 'swiper'
import students1 from '../assets/images/lp/bert-kids/1.png'
import students2 from '../assets/images/lp/bert-kids/2.png'
import students3 from '../assets/images/lp/bert-kids/3.png'
import students4 from '../assets/images/lp/bert-kids/4.png'
import students5 from '../assets/images/lp/bert-kids/5.png'
import students6 from '../assets/images/lp/bert-kids/6.png'
import { containerStyles } from '../assets/styles/Image-swiper.styles'

const ImageSwiper = () => {
  return (
    <div css={containerStyles}>
      <Swiper
        className='swiper-container'
        modules={[Autoplay, FreeMode, Navigation]}
        loop={true}
        freeMode={true}
        autoplay={{
          delay: 0,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }}
        speed={8000}
        slidesPerView={'auto'}

      >
        <SwiperSlide>
          <img src={students1} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={students2} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={students3} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={students4} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={students5} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={students6} />
        </SwiperSlide>
      </Swiper>
    </div>
  )
}

export default ImageSwiper